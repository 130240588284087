.map-container {
  position: relative;
  width: 100%;
  height: 100%;
}

#map {
  // width: 100%;
  // height: calc(100vh - #{$header-height * 2});

  // @media (max-width: $screen-md) {
  //   height: calc(100vh - 5rem);
  // }

  // &.half {
  //   height: calc(50vh - #{$header-height});

  //   @media (max-width: $screen-md) {
  //     height: calc(100vh - 5rem);
  //   }
  // }
}

.map-dot-popup {
  width: 17px;
  height: 17px;
  background-color: $color-primary;
  border: 2px solid $color-white;
  border-radius: 100%;
  overflow: visible;

  &.map-no-dot {
    background-color: transparent;
    border-color: transparent;
  }

  .mapboxgl-popup-tip {
    display: none;
  }

  .mapboxgl-popup-content {
    background-color: transparent;
    box-shadow: none;
    padding: 0;
    width: max-content;
    transform: translateX(-50%);
    position: relative;
    left: 8px;
    top: 8px;
  }

  .map-dot-content-container {
    z-index: 10;

    .image {
      width: 70px;
      height: 70px;
      border-radius: 100%;
      overflow: hidden;
      margin: 0 auto;
      border: 2px solid $color-primary;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }

    .name {
      margin-top: 5px;
      padding: 6px;
      border-radius: 50px;
      background-color: $color-primary;
      color: $color-white;
      text-align: center;
      display: inline-block;
      font-family: $font-family-mono;
      @include font-style(0.875rem, 15px, 600);

      &.yellow {
        background-color: $color-white;
        color: $color-primary;
      }
    }

    &.hidden-map {
      width: 17px;
      height: 17px;

      .name,
      .image {
        display: none;
      }
    }

    &:not(.map-no-hover):hover {
      width: max-content;
      height: auto;
      z-index: 9999;

      .name,
      .image {
        display: block;
        z-index: 9999;
      }
    }

    &.map-no-hover {
    }
  }
}

.map-dong-text-popup {
  &:hover {
    cursor: grab;
  }

  .mapboxgl-popup-tip {
    display: none;
  }

  .mapboxgl-popup-content {
    background-color: transparent;
    box-shadow: none;
    padding: 0;
    width: max-content;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
  }

  .map-dong-text {
    font-size: 16px;
    font-weight: 700;
    text-shadow: -1px 0px #ffffff, 0px 1px #ffffff, 1px 0px #ffffff,
      0px -1px #ffffff;
  }
}
