.btn {
  // 검색, 필터 버튼
  &.btn-enter {
    background-color: #d9f1ef;
    padding: 1rem 1.25rem;
    @include font-style(1.125rem, 1.125rem, 600);
    color: $color-primary;
    background-position: right 1.25rem center;
    background-repeat: no-repeat;
    width: 100%;
    display: block;
    text-align: left;

    @media (max-width: $screen-md) {
      height: 2.5rem;
      line-height: 2.5rem;
      padding: 0 1rem;
      font-size: 0.875rem;
    }

    &.btn-enter-filter {
      background-image: url('../assets/images/ico-filter.svg');
    }
    &.btn-enter-search {
      background-image: url('../assets/images/ico-search.svg');
    }
    &.btn-enter-close {
      background-image: url('../assets/images/ico-close.svg');
    }
  }

  // 필터 라벨 버튼
  &.btn-filter-label {
    @include font-style(0.875rem, 1.625rem, 600);
    padding: 0 1.5rem 0 0.5rem;
    border-radius: 50px;
    background-color: $color-primary;
    color: $color-white;
    background-position: right 0.5rem center;
    background-repeat: no-repeat;
    background-image: url('../assets/images/ico-close-white.svg');

    &.no-close {
      background-image: none;
      padding-right: 0.5rem;
    }
  }

  // 더 보러가기
  &.btn-external {
    height: 70px;
    padding: 0 1.25rem;
    font-family: $font-family-mono;
    @include font-style(18px, 70px, 700);
    border-bottom: 1px solid $color-white;
    text-align: left;
    background-color: $color-primary;
    color: $color-white;
    background-image: url('../assets/images/ico-external.svg');
    background-position: right 1.5rem center;
    background-repeat: no-repeat;

    @media (min-width: $screen-md) {
      height: 80px;
      @include font-style(18px, 80px, 700);
    }

    &:first-child {
      @media (min-width: $screen-md) {
        border-right: 1px solid $color-white;
      }
    }
  }

  // 더 읽어보기
  &.btn-more {
    @include font-style(0.6875rem, 0.6875rem, 700);
    padding: 0.875rem 2rem;
    background-image: url('../assets/images/ico-external-primary.svg');
    background-position: right 1rem center;
    background-repeat: no-repeat;
  }

  // Icon only
  &.btn-icon {
    text-indent: -9999rem;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  &.btn-close {
    @extend .btn-icon;
    width: 24px;
    height: 24px;
    background-image: url('../assets/images/ico-close.svg');
    background-size: 0.875rem;

    &.btn-close-white {
      background-image: url('../assets/images/ico-close-white.svg');
    }
  }

  &.btn-ham {
    @extend .btn-icon;
    width: 24px;
    height: 24px;
    background-image: url('../assets/images/ico-ham-primary.svg');
  }

  &.btn-image-nav {
    @extend .btn-icon;
    width: 18px;
    height: 18px;

    &.left {
      background-image: url('../assets/images/ico-arrow-left.svg');
    }
    &.right {
      background-image: url('../assets/images/ico-arrow-right.svg');
    }
  }

  -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
}
