// Input
.form-input {
  @include font-style(1.125rem, 1rem, 400);
  padding: 0.75rem 1.625rem;
  color: $color-primary;
  position: relative;

  &::placeholder {
    color: $color-primary;
    opacity: 0.5;
  }

  /* &:after {
    content: ' ';
    width: 1px;
    height: 1.25rem;
    background-color: $color-primary;
    position: absolute;
    top: 1rem;
    left: 1.25rem;
  } */

  @media (max-width: $screen-md) {
    line-height: 2.5rem;
    font-size: 0.875rem;
    padding: 0 1rem;
  }
}

.form-checkbox {
  padding-left: 1.8125rem;
  @include font-style(0.875rem, 1.25rem, 400);
  color: $color-primary;
  position: relative;

  @media (hover: hover) {
    &:hover {
      cursor: pointer;
    }
  }

  &:after {
    content: ' ';
    width: 1.25rem;
    height: 1.25rem;
    border: 1px solid $color-primary;
    position: absolute;
    left: 0;
    top: 0;
    background-position: center;
    background-repeat: no-repeat;
  }

  &.active {
    &:after {
      background-image: url('../assets/images/ico-checkbox.svg');
    }
  }
}
