// Archive Item
.archive-item {
  border: 1px solid $color-primary;
  border-bottom: 1px solid $color-primary;
  margin-top: -1px;
  margin-right: -0.5px;
  margin-left: -0.5px;

  @media (max-width: $screen-md) {
    width: 100vw;
    border-right: none;
    border-left: none;
  }

  .archive-item-header {
    border-bottom: 1px solid $color-primary;
  }

  .archive-item-image {
    /* width: 240px;
    height: 170px; */
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    aspect-ratio: 240 / 170;
    width: 40%;

    @media (max-width: $screen-md) {
      aspect-ratio: 1;
      width: 30%;
      /* width: 5.3125rem;
      height: 5.3125rem; */
    }

    img {
      min-width: 100%;
      //min-height: 100%;
    }
  }

  .archive-item-desc {
    width: 60%;
    // height: 170px;

    @media (max-width: $screen-md) {
      width: 70%;
      // height: 5.3125rem;
    }

    &.w-full {
      width: 100%;
    }
  }

  /* &:last-child,
  &:nth-last-child(2),
  &:nth-last-child(3) {
    border-bottom: 1px solid $color-primary;
  } */
}

// Archive Nav
.archive-nav {
  height: $header-height;

  @media (max-width: $screen-md) {
    height: 2.5rem;
  }

  ul {
    li {
      width: 50%;
      height: $header-height;
      text-align: center;
      background-color: #e6e6e6;
      color: rgba(0, 0, 0, 0.3);
      @include font-style(1.125rem, $header-height, 600);

      @media (max-width: $screen-md) {
        width: 100%;
        height: 2.5rem;
        background-color: $color-white;
        color: $color-primary;
        @include font-style(0.875rem, 2.5rem, 600);
      }

      &.current {
        background-color: $color-white;
        color: $color-primary;
        opacity: 1;
      }
    }
  }
}

// Archive filter
.archives-filter-wrap {
  position: sticky;
  top: $header-height;
  z-index: 10;

  @media (max-width: $screen-md) {
    top: 2.5rem;
  }
}

// Archive Info
.archive-info {
  position: fixed;
  bottom: 0;
  z-index: 100;

  transform: translateY(100vh);
  transition: all 300ms ease-in-out;

  width: 100%;

  border-top: 2px solid $color-primary;
  background-color: $color-white;
  color: $color-primary;

  @media (min-width: $screen-xl) {
    min-height: 450px;
  }

  @media (max-width: $screen-md) {
    // height: 100vh;
    border-top: none;
    min-height: 0;
  }

  .archive-info-header {
    position: sticky;
    top: 0;
    border-bottom: 1px solid $color-primary;
    z-index: 100;
    background-color: $color-white;

    h2 {
      @include font-style(23px, 23px, 600);

      @media (max-width: $screen-md) {
        @include font-style(1.125rem, 1.125rem, 700);
      }
    }
  }

  .archive-info-body {
    overflow-y: auto;
    height: calc(100% - 4rem);
  }

  &.show {
    transform: translateY(0);
  }

  &.cover {
    height: calc(100vh - #{$header-height * 2});
    min-height: calc(100vh - #{$header-height * 2});

    @media (max-width: $screen-md) {
      height: 100vh;
      min-height: 100vh;
    }
  }
}

.hover-text {
  @media (hover: hover) {
    &:hover {
      .hover-text-target {
        text-decoration: underline;
      }
    }
  }
}
