header {
  position: sticky;
  top: 0;
  left: 0;
  height: $header-height;
  width: 100%;
  border-bottom: 2px solid $color-primary;
  transition: all 200ms ease-in-out;
  z-index: 9999;

  @media (max-width: $screen-md) {
    height: 2.5rem;

    #header-logo {
      width: 15.625rem !important;
      height: 1.25rem !important;
    }
  }

  // 메뉴
  nav {
    @include font-style(1rem, 1.125rem, 700);
  }

  // Size
  &.header-lg {
    height: 72px;

    @media (max-width: $screen-md) {
      height: 52px;
    }
  }

  #header-nav {
    @media (max-width: $screen-md) {
      display: none;
    }

    ul {
      li.nav-item {
        padding: 2px 0;
        position: relative;
        border-bottom: 1px solid transparent;

        @media (hover: hover) {
          &:hover {
            cursor: pointer;
          }
        }

        .nav-sub-list {
          opacity: 0;
          visibility: hidden;
          transition: all 200ms ease-in-out;
          padding-top: 1.5rem;

          @media (max-width: $screen-md) {
            padding-top: 1.25rem;

            &.show-sub-mobile {
              opacity: 1;
              visibility: visible;
            }
          }
        }

        ul {
          padding: 0 0 0.75rem 0;
          border-left: 2px solid $color-primary;
          border-bottom: 2px solid $color-primary;
          li {
            color: $color-primary;
            padding: 0 0.75rem;
            line-height: 2.5rem;

            @media (max-width: $screen-md) {
              color: $color-white;
            }

            @media (hover: hover) {
              &:hover {
                @media (min-width: ($screen-md + 1)) {
                  cursor: pointer;
                  background-color: #f0f0f0;
                }
              }
            }
          }
        }

        &.active,
        &:hover {
          @media (min-width: ($screen-md + 1)) {
            color: #000;
            border-bottom: 1px solid #000;
          }
        }

        &:hover {
          @media (min-width: ($screen-md + 1)) {
            .nav-sub-list {
              opacity: 1;
              visibility: visible;
            }
          }
        }
      }
    }

    .hover-flip {
      @media (hover: hover) {
        &:hover {
          .hover-flip-target {
            transform: rotate(0deg);
          }
        }
      }
    }
  }

  .btn-ham {
    @media (min-width: $screen-md) {
      display: none;
    }
  }

  &.show-nav {
    @media (max-width: $screen-md) {
      #header-nav {
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: $color-primary;
        color: $color-white;
        padding: 1rem 1.25rem;
        @include font-style(1.25rem, 1.75rem, 500);
      }
    }
  }
}

.top-header {
  top: $header-height;

  @media (max-width: $screen-md) {
    top: 2.5rem;
  }
}
