// Records item
.records-item {
  border: 0.5px solid $color-primary;

  .records-item-header {
    height: 230px;
    border-bottom: 1px solid $color-primary;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;

    @media (max-width: $screen-md) {
      width: 50%;
      height: 10rem;
      border-bottom: none;
    }
  }

  .records-item-body {
    @media (max-width: $screen-md) {
      border-top: 1px solid $color-primary;
    }
  }

  .records-item-para {
    font-family: $font-family-serif;
    color: $color-black;
    @include font-style(28px, 38px, 400);
    display: none;
    background-color: $color-white;
    height: 100%;
  }

  &:hover {
    background-color: $color-white;

    .records-item-para {
      display: block;
    }
  }

  @media (max-width: $screen-md) {
    background-color: $color-white;

    .records-item-para {
      display: block;
      width: 100%;
      position: absolute;
      right: -100%;
      @include font-style(1rem, 1.5rem, 400);
    }
  }
}

// Records info
.records-info {
  position: fixed;
  bottom: 0;
  z-index: 100;

  width: 100%;
  max-height: 75vh;

  background-color: $color-white;
  border-top: 2px solid $color-primary;
  color: $color-primary;

  transform: translateY(100vh);
  transition: all 300ms ease-in-out;

  .records-info-header {
    position: sticky;
    top: 0;
    border-bottom: 1px solid $color-primary;

    @media (max-width: $screen-md) {
      height: 3.4375rem;
    }
  }

  .records-info-nav {
    height: 100%;
  }

  .records-info-body {
    overflow-y: auto;
    height: calc(100% - 72px - 50px);

    .records-info-video {
      border-bottom: 2px dashed $color-primary;
      padding: 3.6vh 7.63vw;
    }

    .records-info-image {
      height: 235px;
      width: auto;
    }

    .records-info-title {
      @include font-style(18px, 18px, 700);
    }
  }

  .records-info-text {
    color: $color-black;
    @include font-style(22px, 34px, 400);
    font-family: $font-family-serif;

    @media (max-width: $screen-md) {
      @include font-style(1.0625rem, 1.625rem, 400);
    }
  }

  .records-info-details {
    position: fixed;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: $color-light-gray;
    z-index: 10;
    transform: translateY(-50px);
    transition: all 300ms ease-in-out;
    overflow-y: auto;

    // 스크롤바 숨기기
    &::-webkit-scrollbar {
      width: 0;
      background: transparent;
      display: none;
    }

    .records-info-details-header {
      height: 50px;
      padding: 0 1.25rem;
      @include font-style(18px, 50px, 700);
      border-bottom: 0.5px solid gainsboro;
      background-image: url('../assets/images/ico-tip-top.svg');
      background-color: $color-light-gray;
      background-repeat: no-repeat;
      background-position: right 1.25rem center;
      position: sticky;
      top: 0;
      z-index: 10;

      display: flex;
      align-items: center;

      &.hide {
        background-image: url('../assets/images/ico-tip-bottom.svg');
      }

      // on mobile view
      @media (max-width: $screen-md) {
        padding: 0.75rem 1rem;
        @include font-style(16px, 22.4px, 700);

        background-image: none;
      }

      @media (hover: hover) {
        &:hover {
          cursor: pointer;
        }
      }
    }

    .records-info-details-body {
      height: calc(100% - 50px);
    }

    &.show {
      transform: translateY(-64vh);
      max-height: 64vh;
      height: 100%;

      .records-info-details-header {
        background-image: url('../assets/images/ico-tip-bottom.svg');
      }
    }

    .min-height {
      // height: 655px;
    }
  }

  &.show {
    transform: translateY(0);
  }

  // Mobile version
  &.records-info-mobile {
    // height: calc(100vh - env(safe-area-inset-bottom));
    height: 100vh;
    height: var(--app-height);
    bottom: auto;
    top: 0;
    border-top: none;

    .records-info-mobile-content {
      height: 100vh;
      height: var(--app-height);
      border-top: 2px;
      overflow-y: auto;
      bottom: auto;
      top: 0;
      padding-bottom: 5rem;
    }

    .records-info-header {
      background-color: $color-white;
      z-index: 100;
    }

    .records-info-body {
      height: auto;

      .records-info-video {
        padding: 0;
        border-bottom: none;
      }

      &.fixed-body {
        position: sticky;
        top: 3.4375rem;
        z-index: 10;
      }
    }

    .records-info-nav {
      // height: 2.5rem;
      position: relative;
      background-color: $color-secondary;
      border-top: 1px solid $color-primary;
      border-bottom: 1px solid $color-primary;

      ul {
        li {
          display: none;
          line-height: 1.125rem;
          // padding: 0.6125rem;
          // height: 2.5rem;

          &.active {
            display: block;
          }
        }
      }

      .btn-info-nav {
        width: 1rem;
        height: 1rem;
        position: absolute;
        right: 1.25rem;
        top: 0.8125rem;
        background-image: url('../assets/images/ico-tip-top.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: 0.75rem 0.4375rem;
      }

      &.show-nav {
        height: auto;
        background-color: transparent;
        border-bottom: 1px solid transparent;

        ul {
          li {
            display: block;

            &.active {
              background-color: $color-secondary;
            }
          }
        }

        .btn-info-nav {
          background-image: url('../assets/images/ico-tip-bottom.svg');
        }
      }
    }

    .records-info-min-height {
      // min-height: calc(100vh - 6.25rem);
    }

    .records-info-details {
      /* position: relative;
      top: 0;
      transform: translateY(0);
      height: 100vh;
      overflow: hidden; */
      // top: 100vh;

      top: auto;
      top: 100vh;
      top: var(--app-height);
      max-height: 100vh;
      max-height: var(--app-height);
      transform: translateY(-2.5rem);
      padding-bottom: 5rem;

      .records-info-details-header {
        position: sticky;
        top: calc(2.5rem - 1px);
        width: 100%;
        z-index: 0;
        background-color: $color-secondary;
        height: auto;

        &.hide {
          background-image: url('../assets/images/ico-tip-top.svg');
        }

        &.records-info-details-header-main {
          position: sticky;
          top: 0;
          z-index: 10;
        }
      }

      &.show {
        top: calc(3.4375rem + 2.5rem - 2px);
      }
    }

    &.fixed-details {
      .records-info-details {
        position: sticky;
        top: 3.4375rem;
        overflow-y: auto;
        padding-bottom: 10rem;

        .records-info-details-header {
          position: sticky;
          top: 2.5rem;
          width: 100%;
          z-index: 0;
          background-color: $color-secondary;

          &.records-info-details-header-main {
            position: sticky;
            top: 0;
            z-index: 10;
            background-color: $color-white;
          }
        }
      }
    }
  }
}

// 이미지 뷰어
.image-viewer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: calc(100dvh - 72px);
  background-color: $color-white;
  color: $color-primary;
  z-index: 300;
  font-size: 1.125rem;

  .image-viewer-name {
    @media only screen and (max-width: $screen-md) {
      flex-wrap: nowrap;
      transform: translateX(0);
      white-space: nowrap;
      will-change: transform;
      animation: marquee 20s linear infinite;

      .name {
        padding-right: 3.4375rem;
      }
    }
  }

  .image-viewer-nav {
    width: 100%;
    height: 70px;
    padding: 0 1.25rem;
    font-weight: 700;
    border-bottom: 1px solid $color-primary;

    @media only screen and (max-width: $screen-md) {
      padding: 0 1.25rem;

      .image-viewer-tools {
        width: 100%;
        position: fixed;
        bottom: 0;
        left: 0;
        height: $header-height;
        display: flex;
        align-items: center;
        padding: 0 1.25rem;
        z-index: 100;
      }
    }
  }

  .image-viewer-content {
    width: 100%;
    height: calc(100% - 40px);
    padding: 20px 0;
    padding-bottom: 50px;
    display: inline-block;
    position: relative;

    @media only screen and (max-width: $screen-md) {
      padding-bottom: $header-height;
    }

    .image-container {
      width: 100%;
      height: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
    }

    img {
      max-width: 100%;
      max-height: 100%;
      display: block;
    }
  }
}
