.logo {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 170px;
  height: 26px;
  transition: all 200ms ease-in-out;

  @media (max-width: $screen-lg) {
    width: 9.0625rem;
    height: 1.4375rem;
  }

  // Colors
  &.logo-green {
    background-image: url('../assets/images/logo-green.svg');
  }
  &.logo-white {
    background-image: url('../assets/images/logo-white.svg');
  }

  // Sizes
  &.logo-lg {
    width: 423px;
    height: 65px;

    @media (max-width: $screen-lg) {
      width: 14.5625rem;
      height: 2.25rem;
    }
  }
}

.with-logo {
  padding-left: 25px;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url('../assets/images/ico-logo.svg');
}

.logo-people {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 550px;
  max-width: 100%;
  aspect-ratio: 550 / 48;
  background-image: url('../assets/images/ico-logo-people.svg');

  @media (max-width: $screen-lg) {
    width: 12.3125rem;
    height: 4.25rem;
    background-image: url('../assets/images/ico-logo-people-mobile.svg');
  }
}
