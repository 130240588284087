.slick-slider {
  height: 600px;

  .slick-dots {
    bottom: 20px;

    li {
      width: 0.5rem;
      height: 0.5rem;
      margin: 0 0.25rem;

      button {
        width: 0.5rem;
        height: 0.5rem;
        padding: 0;
        border-radius: 100%;
        background-color: $color-light-gray;

        &:before {
          content: '';
          display: none;
        }
      }

      &.slick-active {
        button {
          background-color: $color-primary;
        }
      }
    }
  }
}
