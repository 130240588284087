@use 'sass:math';

$img-home-height: 304px;
$img-home-height-mobile: 12.5rem;
$img-home-aspect: 320 / 200;

.home-item {
  position: relative;
  height: $img-home-height;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border: 0.5px solid $color-primary;
  overflow: hidden;

  @media (max-width: $screen-md) {
    width: 100vw;
    // height: $img-home-height-mobile;
    height: auto;
    aspect-ratio: $img-home-aspect;
    border-right: none;
    border-left: none;
  }

  &.home-item-main,
  .slick-slide {
    height: $img-home-height * 2;

    @media (max-width: $screen-md) {
      height: $img-home-height-mobile * 2;
    }

    @media (min-width: $screen-xl) {
      height: $img-home-height * 2;
    }
    @media (min-width: $screen-2xl) {
      height: $img-home-height * 3;
    }
  }

  .home-bg-item {
    height: $img-home-height * 2;

    @media (max-width: $screen-md) {
      height: $img-home-height-mobile * 2;
    }

    @media (min-width: $screen-xl) {
      height: $img-home-height * 2;
    }
    @media (min-width: $screen-2xl) {
      height: $img-home-height * 3;
    }
  }
}

// Home archive item
.home-archive-item {
  @extend .home-item;

  @media (hover: hover) {
    &:hover {
      cursor: pointer;
    }
  }

  .home-archive-item-info {
    position: absolute;
    top: 100%;
    right: 0;
    width: 50%;
    background-color: $color-secondary;
    color: $color-primary;
    transition: all 300ms ease-in-out;
    transform: translateY(-40px);

    @media (max-width: $screen-md) {
      width: 100%;
    }

    .home-archive-item-info-header {
      height: 40px;
      line-height: 40px;
      padding: 0 15px;
    }

    .home-archive-item-info-body {
      border-top: 1px solid $color-primary;
      padding: 10px 20px;

      @media (max-width: $screen-md) {
        padding: 0.6125rem 1rem;
      }

      p {
        margin-bottom: 0;
        font-family: $font-family-mono;
        @include font-style(16px, 25px, 400);

        @media (max-width: $screen-md) {
          @include font-style(0.875rem, 1.25rem, 400);
        }
      }
    }
  }

  &:hover {
    .home-archive-item-info {
      transform: translateY(-100%);
    }
  }
}

// Home record item
.home-record-item {
  @extend .home-item;

  @media (hover: hover) {
    &:hover {
      cursor: pointer;
    }
  }

  background-color: $color-white;

  .home-record-content {
    height: $img-home-height;

    @media (max-width: $screen-md) {
      width: 100%;
      height: auto;
      aspect-ratio: $img-home-aspect;
    }

    &.home-record-content-video {
      position: relative;
      video {
        object-fit: cover;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
      }
    }

    .home-record-content-body {
      p {
        font-family: $font-family-serif;
        @include font-style(30px, 40px, 400);

        @media (max-width: $screen-md) {
          @include font-style(1.25rem, 1.75rem, 400);
        }
      }
    }

    .home-record-content-footer {
      border-top: 1px solid $color-primary;
    }

    // Mobile 용
    @media (max-width: $screen-md) {
      &.home-record-content-hover {
        position: absolute;
        top: 0;

        .home-record-content-body,
        .home-record-content-footer {
          background-color: $color-white;
        }

        .home-record-content-body {
          opacity: 0;
          visibility: hidden;
          transition: all 500ms ease-in-out;
          position: relative;
        }

        &:hover {
          .home-record-content-body {
            opacity: 1;
            visibility: visible;
          }
        }
      }
    }
  }

  &:hover {
    background-color: $color-white;
  }
}

// Home keyword item
$keywordWidth: 1280;
$keywordHeight: 911;
$keywordWidthXl: 639;
$keywordHeightXl: 607;
$keywordWidthLg: 720;
$keywordHeightLg: 607;
$keywordWidthSm: 320;
$keywordHeightSm: 400;

// (top left)
$keywords: (101 163) (314 939) (116 1066) (628 1030) (169 1066) (298 763)
  (721 178) (517 493) (101 413) (338 589) (456 788) (456 413) (748 1153)
  (736 750) (60 750) (344 274) (830 615) (345 707) (289 694) (264 378);

$keywordsXl: (141 88) (352 462) (113 413) (198 298) (291 263) (252 95) (252 546)
  (235 399) (50 374) (259 174);

$keywordsLg: (141 40) (62 232) (224 154) (158 546) (199 238) (114 453);

$keywordsSm: (48 37) (134 195) (102 243) (132 134) (91 83) (152 37) (146 265)
  (88 157) (44 231) (37 148);

@function getPercentage($base, $size) {
  @return math.div($size, $base) * 100%;
}

.home-keyword-item {
  position: absolute;
  transform-origin: center;
  display: flex;
  justify-content: center;
  align-items: center;

  .dot {
    position: absolute;
    left: 50%;
    top: 50%;
    margin-top: 1.5625rem * -0.5;
    margin-left: 1.5625rem * -0.5;
    width: 1.5625rem;
    height: 1.5625rem;
    border-radius: 3rem;
    position: absolute;
    display: block;
    background: $color-primary;
    z-index: 0;
    transition: all 200ms ease-in-out;
  }

  .name {
    position: relative;
    width: max-content;
    border-radius: 3rem;
    color: $color-white;
    padding: 0.375rem 0.5rem;
    transition: all 200ms ease-in-out;
    @include font-style(0.875rem, 1.125rem, 700);
    display: block;
    text-align: center;
    z-index: 1;
    opacity: 0;
  }

  &.show-keyword {
    @media (hover: hover) {
      &:hover {
        cursor: pointer;
      }
    }

    .name {
      width: max-content;
      opacity: 1;
    }
    .dot {
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      margin-left: 0;
      margin-top: 0;
    }
  }

  @media (hover: hover) {
    &:hover {
      cursor: pointer;

      .name {
        width: max-content;
        opacity: 1;
      }
      .dot {
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        margin-left: 0;
        margin-top: 0;
      }
    }
  }

  @for $i from 1 through length($keywords) {
    &.home-keyword-item-#{$i} {
      top: getPercentage($keywordHeight, nth(nth($keywords, $i), 1));
      left: getPercentage($keywordWidth, nth(nth($keywords, $i), 2));
    }
  }

  @media (max-width: $screen-2xl) {
    display: none;

    @for $i from 1 through length($keywordsXl) {
      &.home-keyword-item-#{$i} {
        top: getPercentage($keywordHeightXl, nth(nth($keywordsXl, $i), 1));
        left: getPercentage($keywordWidthXl, nth(nth($keywordsXl, $i), 2));
        display: block;
      }
    }
  }

  @media (max-width: $screen-xl) {
    display: none;

    @for $i from 1 through length($keywordsLg) {
      &.home-keyword-item-#{$i} {
        top: getPercentage($keywordHeightLg, nth(nth($keywordsLg, $i), 1));
        left: getPercentage($keywordWidthLg, nth(nth($keywordsLg, $i), 2));
        display: block;
      }
    }
  }

  @media (max-width: $screen-sm) {
    display: none;
    /* width: 1.125rem;
    height: 1.125rem; */

    @for $i from 1 through length($keywordsSm) {
      &.home-keyword-item-#{$i} {
        top: getPercentage($keywordHeightSm, nth(nth($keywordsSm, $i), 1));
        left: getPercentage($keywordWidthSm, nth(nth($keywordsSm, $i), 2));
        display: block;
      }
    }
  }
}
