.notice-item {
  .notice-item-icon {
    margin-right: 1.25rem;
    display: none;
  }

  &:hover {
    font-weight: bold;
    background-color: #c2d0d0;
    color: $color-primary;
    cursor: pointer;

    .notice-item-icon {
      display: block !important;
    }
  }
}

.pagination {
  li {
    padding: 0 0.5rem;
  }
  .selected {
    border: 1px solid $color-primary;
  }
}
