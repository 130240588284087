:root {
  --app-height: 100%;
}

* {
  box-sizing: border-box;
}

html,
body {
  padding: 0;
  margin: 0;
  width: 100vw;
  height: 100svh;
  overflow-x: hidden !important;
}

html,
body,
#root {
}

/* // iPhone X
@media only screen and (max-width: 414px) {
  html {
    font-size: 15px;
  }
}

// iPhone 6,7,8,SE2
@media only screen and (max-width: 375px) {
  html {
    font-size: 14px;
  }
}

// Galaxy
@media only screen and (max-width: 360px) {
  html {
    font-size: 13px;
  }
}

// iPhone SE
@media only screen and (max-width: 320px) {
  html {
    font-size: 12px;
  }
} */

body {
  color: $color-black;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  font-family: $font-family-sans-serif;
  font-size: $font-size-base;
  line-height: $line-height-base;
}

// 스크롤바 숨기기
/* ::-webkit-scrollbar {
  width: 0;
  background: transparent;
  display: none;
} */

#wrap {
  /* position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: calc(100vh - env(safe-area-inset-bottom)); */
  // overflow: hidden;
  // min-height: -webkit-fill-available;
  // height: calc(100vh - env(safe-area-inset-bottom));

  min-height: 100svh;
  display: flex;
  flex-direction: column;
}

#main {
  flex: 1;
  min-height: 70vh;
}

.font-chulae {
  font-family: $font-family-serif;
}
.font-noto-sans-mono {
  font-family: $font-family-sans-serif;
}

// Color
.color-primary {
  color: $color-primary;
}
.color-white {
  color: $color-white;
}
.color-black {
  color: $color-black;
}
.color-gray {
  color: $color-gray;
}

.bg-primary {
  background-color: $color-primary;
}
.bg-white {
  background-color: $color-white;
}
.bg-black {
  background-color: $color-black;
}
.bg-gray {
  background-color: $color-gray;
}
.bg-secondary {
  background-color: $color-secondary;
}

.bordered-t {
  border-top: 1px solid $color-primary;

  @media (max-width: $screen-md) {
    &.md-bordered-t-0 {
      border-top: none;
    }
  }
}
@media (max-width: $screen-md) {
  .md-bordered-t {
    border-top: 1px solid $color-primary;
  }
}

.bordered-b {
  border-bottom: 1px solid $color-primary;

  @media (max-width: $screen-md) {
    &.md-bordered-b-0 {
      border-bottom: none;
    }
  }
}
@media (max-width: $screen-md) {
  .md-bordered-b {
    border-bottom: 1px solid $color-primary;
  }
}

.bordered-r {
  border-right: 1px solid $color-primary;

  @media (max-width: $screen-md) {
    &.md-bordered-r-0 {
      border-right: none;
    }
  }
}
@media (max-width: $screen-md) {
  .md-bordered-r {
    border-right: 1px solid $color-primary;
  }
}

.bordered-l {
  border-left: 1px solid $color-primary;

  @media (max-width: $screen-md) {
    &.md-bordered-l-0 {
      border-left: none;
    }
  }
}
@media (max-width: $screen-md) {
  .md-bordered-l {
    border-left: 1px solid $color-primary;
  }
}

.bordered-bold {
  border-width: 2px;
}
.bordered-light {
  border-color: rgba(0, 159, 150, 0.2);
}

.min-height-full {
  min-height: calc(100vh - #{$header-height} - 70px - 60px);

  @media (max-width: $screen-md) {
    min-height: 0;
  }
}

.para-lg {
  @include font-style(1.5rem, 34px, 700);

  @media (max-width: $screen-md) {
    @include font-style(1.25rem, 1.125rem, 700);
  }
}

p {
  word-break: keep-all;
}

// Label
.label {
  font-family: $font-family-mono;
  @include font-style(0.75rem, 1.25rem, 700);
  height: 1.25rem;
  color: $color-white;
  background-color: $color-primary;
  padding: 0 6px;
  border-radius: 2rem;
}
.label-tip {
  width: 5px;
  height: 1.25rem;
  background-image: url('../assets/images/ico-tip-right.svg');
  background-position: center;
  background-repeat: no-repeat;
}
.label-history {
  background: #edf9ff;
  mix-blend-mode: multiply;
  border-radius: 0.25rem;
  padding: 3px;
  z-index: -1;
  @include font-style(0.8125rem, 1.0625rem, 600);
}

// With pin
.with-pin {
  padding-left: 11px;
  background-position: left center;
  background-repeat: no-repeat;
  background-image: url('../assets/images/ico-pin.svg');
  background-size: 6.3px 9.3px;

  &.with-pin-lg {
    padding-left: 22px;
    background-size: 10.53px 14.88px;
  }
}

// 영상
.video-container {
  overflow: hidden;
  position: relative;
  width: 100%;

  &:after {
    padding-top: 56.25%;
    display: block;
    content: '';
  }

  iframe,
  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
