@font-face {
  font-family: 'Chulae';
  font-style: normal;
  font-weight: 400;
  src: url('../assets/fonts/chulae-Regular.woff2') format('woff2'),
    url('../assets/fonts/chulae-Regular.woff') format('woff');
}

@font-face {
  font-family: 'TlabWalkingOnJune';
  font-style: normal;
  font-weight: 400;
  src: url('../assets/fonts/TlabWalkingOnJune-Light.otf') format('opentype');
}

@font-face {
  font-family: 'TlabWalkingOnJune';
  font-style: normal;
  font-weight: 500;
  src: url('../assets/fonts/TlabWalkingOnJune-Medium.otf') format('opentype');
}

@font-face {
  font-family: 'Noto Sans CJK KR Bold';
  font-style: bold;
  font-weight: 700;
  src: url('../assets/fonts/NotoSansCJKkr-Bold.otf') format('opentype');
}
