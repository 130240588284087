$color-black: #262626;
$color-white: #ffffff;
$color-gray: #808080;
$color-light-gray: #f0f0f0;
$color-primary: #3b6161;
$color-secondary: #c2d0d0;
$color-yellow: #fcffd9;

$colorList: (
  'black': $color-black,
  'white': $color-white,
  'gray': $color-gray,
  'light-gray': $color-light-gray,
  'primary': $color-primary,
  'secondary': $color-secondary,
  'yellow': $color-yellow,
);

$font-family-serif: 'Chulae', serif;
$font-family-sans-serif: 'Noto Sans KR', 'Apple Gothic', 'HY Gulim',
  'MalgunGothic', 'HY Dotum', 'Lexi Gulim', 'Helvetica Neue', 'Helvetica',
  'Arial', sans-serif;
$font-family-mono: 'Noto Sans KR', 'Apple Gothic', 'HY Gulim', 'MalgunGothic',
  'HY Dotum', 'Lexi Gulim', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
$font-size-base: 1rem;
$line-height-base: 1.5;

$screen-sm: 501px;
$screen-md: 768px;
$screen-lg: 1024px;
$screen-xl: 1601px;
$screen-2xl: 2551px;

// Mixin
@mixin font-style($size, $lineHeight, $weight) {
  font-size: $size;
  line-height: $lineHeight;
  font-weight: $weight;
}

@each $colorName, $hex in $colorList {
  .color-#{$colorName} {
    color: $hex;
  }
  .bg-#{$colorName} {
    background-color: $hex;
  }
  .border-#{$colorName} {
    border-color: $hex;
  }
  .hover\:color-#{$colorName}:hover {
    color: $hex;
  }
  .hover\:bg-#{$colorName}:hover {
    background-color: $hex;
  }
}

// Components
$header-height: 3rem;
